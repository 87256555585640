const URL = process.env.REACT_APP_WEBSOCKET_URL!;
export const client = reconnectingSocket(URL);

function reconnectingSocket(url: string) {
  let client: WebSocket;
  let isConnected = false;
  let messageListeners: { (data: string): void }[] = [];
  let stateChangeListeners: { (state: boolean): void }[] = [];

  function addMessageListener(listener: (data: string) => void) {
    messageListeners.push(listener);
    return () => {
      messageListeners = messageListeners.filter(l => l !== listener);
    };
  }

  function addStateChangeListener(listener: (state: boolean) => void) {
    stateChangeListeners.push(listener);
    return () => {
      stateChangeListeners = stateChangeListeners.filter(l => l !== listener);
    };
  }

  function start() {
    client = new WebSocket(url);

    client.onopen = () => {
      isConnected = true;
      stateChangeListeners.forEach(fn => fn(true));
    }

    client.onmessage = (event) => {
      messageListeners.forEach(listener => listener(event.data));
    }

    client.onerror = (event) => console.error(event);

    client.onclose = () => {
      isConnected = false;
      stateChangeListeners.forEach(listener => listener(false));
      setTimeout(start, 3000);
    }
  }

  start();

  return {
    addMessageListener,
    addStateChangeListener,
    getClient: () => client,
    isConnected: () => isConnected,
  };
}