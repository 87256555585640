import React from "react";
import styles from "./ConnectionStatus.module.css";

type ConnectionStatusProps = {
  isConnected: boolean;
};

export function ConnectionStatus({isConnected}: ConnectionStatusProps) {
  return (
    <div
      className={isConnected ? styles.connected : styles.waitingConnection}
    />
  );
}