import React, { useEffect, useState } from "react";
import { client } from "./reconnecting-ws";
import { ConnectionStatus } from "./components/ConnectionStatus";
import { v4 as uuidv4 } from 'uuid';
import { JoinGameMessage, ServerMessage } from "./types";
import Game from "./Game";

const userId = uuidv4();

function App() {
  const [isConnected, setIsConnected] = useState(false);
  const [gameId, setGameId] = useState<string>();

  useEffect(() => {
    return client.addStateChangeListener(setIsConnected);
  }, []);

  useEffect(() => {
    return client.addMessageListener((data) => {
      const message: ServerMessage = JSON.parse(data);
      if (message.type === "game-joined") {
        setGameId(message.gameId);
      }
    })
  }, []);

  useEffect(() => {
    if (isConnected) {
      joinGame();
    }
  }, [isConnected]);

  const joinGame = () => {
    const payload: JoinGameMessage = {
      type: "join-game",
      userId: userId,
    };
    client.getClient().send(JSON.stringify(payload));
  };

  return (
    <div className="flex items-center justify-center text-white min-h-screen flex-col">
      <div className="flex items-center justify-center">
        <div className="text-5xl mr-4">Tic Tac Toe</div>
        <ConnectionStatus isConnected={isConnected}/>
      </div>
      {gameId && <Game gameId={gameId} userId={userId}/>}
    </div>
  );
}

export default App;