import React, { useEffect, useState } from "react";
import {
  Board,
  Cell, JoinGameMessage,
  Mark,
  PlaceMarkMessage,
  ServerMessage,
} from "./types";
import { client } from "./reconnecting-ws";

type GameState = {
  board: Board;
  currentPlayer: Mark;
  winner?: Mark;
  state: "CREATED" | "PLAYING" | "ENDED";
  markByPlayerId: {
    [key: string]: Mark
  }
};

const initialState: GameState = {
  board: Array(3).fill(new Array(3).fill("")),
  currentPlayer: "X",
  state: "CREATED",
  markByPlayerId: {}
};

type GameProps = {
  gameId: string
  userId: string
}

function Game({ gameId, userId }: GameProps) {
  const [gameState, setGameState] = useState(initialState);
  const { board, currentPlayer, winner, markByPlayerId, state } = gameState;

  useEffect(() => {
    return client.addMessageListener((data) => {
      const message: ServerMessage = JSON.parse(data);
      if (message.type === "game-updated") {
        setGameState(message);
      }
    })
  }, []);

  const placeMark = (rowIndex: number, colIndex: number) => {
    const payload: PlaceMarkMessage = {
      type: "place-mark",
      gameId: gameId,
      row: rowIndex,
      col: colIndex,
      mark: currentPlayer,
    };
    client.getClient().send(JSON.stringify(payload));
  };

  const joinGame = () => {
    const payload: JoinGameMessage = {
      type: "join-game",
      userId: userId
    };
    client.getClient().send(JSON.stringify(payload));
  };

  function getText() {
    switch (gameState.state) {
      case "CREATED":
        return "Waiting for players...";
      case "PLAYING":
        return markByPlayerId[userId] === currentPlayer ? `Your turn (${currentPlayer})` : `Their turn (${currentPlayer})`
      case "ENDED":
        if (winner === undefined) {
          return "You tied"
        } else if (winner === markByPlayerId[userId]) {
          return "You won"
        } else {
          return "You lost"
        }
    }
  }

  function renderCurrentPlayer() {
    return <div className="text-2xl text-purple-200">
      {getText()}
    </div>;
  }

  return (
    <>
      <div className="grid grid-cols-3 gap-1 bg-white">
        {board.map((row, rowIndex) =>
          row.map((cell, colIndex) => (
            <GridCell
              onClick={() => placeMark(rowIndex, colIndex)}
              mark={cell}
              canClick={cell === "" && state === "PLAYING" && markByPlayerId[userId] === currentPlayer}
            />
          ))
        )}
      </div>
      <div className="mt-6 text-l text-purple-200">{gameId}</div>
      {renderCurrentPlayer()}
      {gameState.state === "ENDED" && (
        <button
          className="mt-6 text-2xl bg-purple-100 text-purple-900 pad px-4 py-2 rounded-md"
          onClick={joinGame}
        >
          Restart
        </button>
      )}
    </>
  );
}

interface GridCellProps {
  mark: Cell;
  onClick: () => void;
  canClick: boolean;
}

function GridCell(props: GridCellProps) {
  return (
    <div
      className={`h-24 w-24 flex justify-center items-center text-5xl ${
        props.canClick && "cursor-pointer"
      }`}
      style={{ backgroundColor: "#3b3868", fontFamily: "Quicksand Bold" }}
      onClick={props.canClick ? props.onClick : () => {
      }}
    >
      {props.mark}
    </div>
  );
}

export default Game;
